module.exports = {
    base_url: "https://learningmanager.adobe.com/primeapi/v2",
    signin_url: "https://learningmanager.adobe.com",
    // front_end_app_url: "http://localhost:2000",
    client_id: "0b2b9d91-64a2-4b95-9740-faaefe97cd84",
    client_secret: "91866d82-6d2f-4ff0-93ba-d318af50e68d",
    // custom_app_url: "https://dev.dxenablementhub.adobe.com", //"http://localhost:3001" "http://52.205.136.34:5003",
    account_id: 115478,
    application_url: "https://api.dev.dxenablementhub.adobe.com/api",
    show_maintenance_view: false
};

// module.exports = {
//     base_url: "https://captivateprime.adobe.com",
//     app_back_end_url_token: "http://localhost:3001/v2/user/oauth/token",
//     app_back_end_url_refresh_token: "http://localhost:3001/v2/user/oauth/token/refresh",
//     front_end_app_url: "http://localhost:2000"//"https://harbingercaptivateprime.z13.web.core.windows.net"
// }
